import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useState, useEffect,useContext } from "react";
import LoadingSpinner from '../Components/LoadingSpinner';
import { DateTime } from "luxon";
import {  AiOutlineHome } from "react-icons/ai";
import { BsChevronDoubleRight} from "react-icons/bs";
import Page from "../Components/Page";


const PlayerHome = ()=>{
    const navigate = useNavigate();
    var CurrencyFormat = require('react-currency-format');
    const [quest, setQuest] = useState([{}]);    

    const[showPage, setShowPage] = useState(false);
    const[showComing, setShowComing] = useState(false);
    const [comingSoon, setComingSoon] = useState([{}]);

    const [newsAlerts, setNewsAlerts] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        allActiveQuest();
        allComingSoonQuest();
        latestNewsAlert();
       }, []);

    const latestNewsAlert = async ()=>{
        const response = await fetch('/gameapi/latestAlert/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            status: 'SHOW',
           })
      });
      const data = await response.json();
      if(! ('message' in data)){   
          setNewsAlerts(data);
          setShowAlert(true);
        }
      }

    const allActiveQuest = async ()=>{
        const response = await fetch('/gameapi/allActiveQuests/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        setQuest(data);
        setShowPage(true);
    }

    const allComingSoonQuest = async ()=>{
        const response = await fetch('/gameapi/getAllQuestCtgry/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                status: 'PUBLISHED',
             })
        });
        const data = await response.json();
        if(data.length !== 0){
            setComingSoon(data);
            setShowComing(true);
        }
        
    }




    return (<>
        <Page title='Home - Player'/>
        <Row className='home-header'>  
        
            <div className="p-1 mb-2 text-center" >
            <span className="inv-color fs-1 roboto-font"> Inve$tor </span> 
            <span className="quest-color fs-1 roboto-font"> Quest TT 2.0 <AiOutlineHome size={30} style={{ fill: 'white' }} /> 
            </span>
            
            <p className="text-light fs-3 roboto-font"> 
            Unlock The Investor In You! </p>
                
            </div>   
    
        </Row>

        <Row className='border border-2 border-dark mb-5'>
        <Col xs={1}></Col>   

        <Col xs={10} >
        
        { (showPage) ?
        <>
       
        
        
        <Row>
            
        <h3 className='home-headings home-heading-color  mt-4 mb-2'>Join or Play Available Quests: </h3>
        
        {/* Start Test*/}

        { quest.map((q, index)=>(

        <Row className=" mb-2" key={q.id}>
        

            <Table responsive bordered className='border-secondary border-2'>
                <thead className='border  border-secondary border-1'>
                    <th colSpan={3} className="roboto-font text-light fs-3 quest-header-bg px-3 py-1 border-secondary border-2"> 
                    {q.name}                   
                    </th>
                </thead>
                <tbody>
                    <tr >
                        <td rowSpan={3} width='50%' className='px-3  '>
                        <h4 className='home-about-color'> About this Quest</h4>
                        <span  style={{ textAlign: 'justify' }}> {q.description} </span>
                        </td>
                        <td width='20%' className="fs-5 roboto-font ttsec-blue fw-bold">Starting Value </td>
                        <td className="fs-4 currency text-success fw-bold"> 
                            <CurrencyFormat value={parseFloat(q.start_value)}  
                                fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                                thousandSeparator={true} prefix={'$'} 
                            />
                        </td>
                    </tr>

                    <tr>
                    <td className='summary-heading roboto-font'>  Quest Start Date: </td>
                     <td className='fs-5 roboto-font'> 
                        {DateTime.fromISO(q.start_date).toLocaleString(DateTime.DATE_MED)} 
                    </td>
                    </tr>
                    
                    <tr>
                            <td className='summary-heading roboto-font'>  Quest End Date: </td>

                            {(q.type === 'ON_GOING') ?
                                <td className='fs-5 roboto-font'> (this is an ongoing quest) </td>
                                :
                                <td className='fs-5 roboto-font'> {DateTime.fromISO(q.end_date).toLocaleString(DateTime.DATE_MED)} </td>

                            }

                    </tr>

                    <tr className='quest-header-bg'>
                        <td colSpan={3} className='text-end px-3 py-2 '>
                        <Button variant="dark" onClick={() => navigate(`/game/viewQuest/${q.id}`)}> View  Quest <BsChevronDoubleRight/>
                     </Button>
                        </td>                    
                    </tr>

                </tbody>
            </Table>

        </Row>

        ))}

        {/* End Test*/}

        {(showComing) ?
        <> 
        <Row>
        <h3 className='home-headings bonds-inv-c2  mt-4 mb-2'>Coming Soon: </h3>
        </Row>

        {/* Start Test*/}

        { comingSoon.map((c, index)=>(

        <Row className=' mb-2' key={c.id}>


    <Table responsive bordered  className='border-info '>
        <thead bordered className='border  border-info border-1 '>
            <th bordered colSpan={3} className="roboto-font text-dark fs-2 coming-soon-header-bg px-3 py-1"> 
            {c.name}                   
            </th>
        </thead>
        <tbody >
            <tr className='px-3'>
                <td rowSpan={3} width='50%' className='px-3'>
                <h4 className='text-success'> About this Quest</h4>
                <span className='home-quest-desc ' style={{ textAlign: 'justify' }}> {c.description} </span>
                </td>
                <td width='20%' className="fs-5 roboto-font">Starting Value </td>
                <td className="fs-4 currency text-primary"> 
                    <CurrencyFormat value={parseFloat(c.start_value)}  
                        fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                        thousandSeparator={true} prefix={'$'} 
                    />
                </td>
            </tr>

            <tr>
            <td className='summary-heading roboto-font'>  Quest Start Date: </td>
             <td className='fs-5 roboto-font'> 
                {DateTime.fromISO(c.start_date).toLocaleString(DateTime.DATE_MED)} 
            </td>
            </tr>
            
            <tr>
                    <td className='summary-heading roboto-font'>  Quest End Date: </td>

                    {(c.type === 'ON_GOING') ?
                        <td className='fs-5 roboto-font'> (this is an onging quest) </td>
                        :
                        <td className='fs-5 roboto-font'> {DateTime.fromISO(c.end_date).toLocaleString(DateTime.DATE_MED)} </td>

                    }

            </tr>

        </tbody>
    </Table>

</Row>

))}

{/* End Test*/}

        
       
          </>
        :
        <></>
        }

{(showAlert) ?
            <Row>
            <h3 className='playfair-font fs-2 text-danger  mt-4 mb-2'>Latest News Alert: </h3>
                <Table responsive bordered className='mb-3'>
                <thead className='border  border-secondary border-1 '>               
                    <tr className='bg-alerts text-light roboto-font fs-5 mt-3 px-1'>
                    <th colSpan={2}>{DateTime.fromISO(newsAlerts.alert_date).toLocaleString(DateTime.DATE_HUGE)}</th>
                    </tr>              
                </thead>
                <tbody>
                <tr className=' home-header'>
                    <td width='30%' className='text-center border  border-secondary border-1' >
                    <Image  src={require('../assets/images/Alert0.png')}  roundedCircle width="70%" height="auto" fluid/>
                    </td>
                    <td className='bg-light'>
                    <p className='currency fs-4 fw-bold my-2'> {newsAlerts.heading}</p>
                    <p className='currency fs-6 justify-text'> {newsAlerts.details}</p>
                    </td>
                </tr>
                </tbody>
    
            </Table>
            </Row>
            :
                <></>
            }


        </Row>
        </>
        :
        <>
            <div className="p-3 mb-3 text-center">
                <LoadingSpinner/>
            </div>
        </>

        } {/* End Show active quest */}

    
           

            


        </Col>    

        <Col xs={1}></Col>    
        
    </Row> 




    </>);
}

export default PlayerHome;
