import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect,useContext } from "react";
import LoadingSpinner from '../Components/LoadingSpinner';
import {  AiOutlineUser } from "react-icons/ai";
import AuthContext from '../Components/AuthContext';
import { DateTime } from "luxon";
import Page from "../Components/Page";

const PlayerAccount = ()=>{
  const navigate = useNavigate();
  const[showPage, setShowPage] = useState(false);
  const [details, setDetails] = useState({});
  const { authToken }= useContext(AuthContext);
  const [broker, setBroker] = useState(); 
  const [ageGroup, setAgeGroup] = useState();

  useEffect(() => {
    getProfileDetails();      
  }, []);

  useEffect(() => {
    if(showPage) {
      if(details.broker_tag === 'Elite') 
        setBroker('Elite Securities Limited');
    else if(details.broker_tag === 'Capital')
        setBroker('360 Capital Market Brokers Limited'); 
    else
        setBroker('Butterfly Interactive Brokers Limited');
    
    let age = parseInt(details.age_group);
    if(age === 1)
      setAgeGroup("13 - 19");
    else if(age === 2)
      setAgeGroup("20 - 35"); 
    else if(age === 3)
      setAgeGroup("36 - 45");
    else if(age === 4)
      setAgeGroup("46 - 55");  
    else
    setAgeGroup("56+");            
   
    }
  }, [showPage]);

 

  const getProfileDetails = async () =>{

    const response = await fetch('/api/playerProfile/', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + String(authToken.access)
        }
    })
    const data = await response.json();
    if(!('message' in data)){
      setDetails(data);
      setShowPage(true);
    }
    else alert(data.message)
    
}

  

    return (<>
        <Page title='User Account'/>
        <Row className='home-header'>  
        
        <div className="p-1 mb-2 text-center" >
        <span className="inv-color fs-1 roboto-font"> Inve$tor </span> 
        <span className="quest-color fs-1 roboto-font"> Quest TT 2.0 <AiOutlineUser size={30} style={{ fill: 'white' }} /> 
        </span>
        
        <p className="text-light fs-3 roboto-font"> 
        Unlock The Investor In You! </p>
            
        </div>   

    </Row>

    <Row className='border border-2 border-dark mb-5'>
    <Col xs={1}></Col>   

    <Col xs={10} >
    
    { (showPage) ?
    <>
    <Row className="my-1 align-items-center"> {/* header row*/}
          <Col xs={12} sm={4} className = "text-center my-3">            
          <Image  src={require(`../assets/images/${details.avatar_image}`)}   width="50%" height="auto" />
          </Col>
          <Col xs={12} sm={8}>
          <p className='fs-3 roboto-font ttsec-blue  my-2'> {details.user.first_name}  {details.user.last_name}
          </p>
          <p className=" fs-4 currency "> Investor Quest Career: <span className='text-success roboto-font'> {details.player_avatar}</span>    </p> 
          </Col>
      </Row> {/* close header row*/}
    
    <Row>
      <Table responsive="lg" bordered >
        <thead>
          <tr className='bg-profile'>
            <th colSpan={2} className='text-light roboto-font fs-5'>Account Details</th>
          </tr>
        </thead>
        <tbody className='fs-5 roboto-font '>
          <tr>
            <td width="30%" className='ps-3 currency ttsec-blue fw-bold'>Username</td>
            <td className='ps-4 '>{details.playername} </td>
          </tr>

          <tr>
            <td width="30%" className='ps-3 currency ttsec-blue fw-bold'>First Name*</td>
            <td className='ps-4'>{details.user.first_name} </td>
          </tr>

          <tr>
            <td width="30%" className='ps-3 currency ttsec-blue fw-bold'>Last Name*</td>
            <td className='ps-4 '>{details.user.last_name} </td>
          </tr>

          <tr>
            <td width="30%" className='ps-3 currency ttsec-blue fw-bold'>Email</td>
            <td className='ps-4 '>{details.user.email} </td>
          </tr>
          
          <tr>
            <td width="30%" className='ps-3 currency ttsec-blue fw-bold'>Age Group*</td>
            <td className='ps-4 '>{ageGroup} years</td>
          </tr>

          <tr>
            <td width="30%" className='ps-3 currency ttsec-blue fw-bold'>Gender</td>
            {(details.gender === 'NONE_ENTERED') ?
            <td className='ps-4'>Prefer not to say </td>
            :
            <td className='ps-4'>{details.gender} </td>
            }
          </tr>

          <tr>
            <td width="30%" className='ps-3 currency ttsec-blue fw-bold'>Location</td>
            <td className='ps-4'>{details.geo_location} </td>
          </tr>

          <tr>
            <td width="30%" className='ps-3 currency ttsec-blue fw-bold'>Your Broker</td>
            <td className='ps-4'>{broker} </td>
          </tr>

          <tr>
            <td width="30%" className='ps-3 currency ttsec-blue fw-bold'>Date Joined</td>
            <td className='ps-4'>
            {DateTime.fromISO(details.user.date_joined).toLocaleString(DateTime.DATE_HUGE)}                   
            </td>
          </tr>
          <tr >
            <td colSpan={2} className='text-end'>
              <Button variant='warning' size='sm' onClick={() => navigate('/game/updateAccount/')} >
                Update Details
              </Button>
            </td>
            
          </tr>
        </tbody>
      </Table>
      <p className='text-danger'>*Only these can be updated.</p>
        
         

    


    </Row>
    </>
    :
    <>
        <div className="p-3 mb-3 text-center">
            <LoadingSpinner/>
        </div>
    </>

    }


    </Col>    

    <Col xs={1}></Col>    
    
</Row>
      
   
    </>);
}

export default PlayerAccount;