const ageGroupMapping = {
    '0': 'No Age Restriction',
    '1': '(13 - 19)',
    '2': '(20 - 35)',
    '3': '(36 - 45)',
    '4': '(46 - 55)',
    '5': '(56+)',
}
const ageGroupMappingForPlayer = {
    '0': 'All Players',
    '1': '13 - 19',
    '2': '20 - 35',
    '3': '36 - 45',
    '4': '46 - 55',
    '5': '56+',
}
function getApplicableAgeGroupsDescription(applicableAgeGroups, mapping) {
    let description = 'No Age Restriction';
    if (!applicableAgeGroups) {
        return description
    }
    const _applicableAgeGroups = applicableAgeGroups.split(',');
    if (_applicableAgeGroups.length === 0) {
        return description
    }
    const d = _applicableAgeGroups.sort().map(applicableAgeGroup => mapping[applicableAgeGroup]);
    if(d.length === 1){
        return d.join('');
    }
    const lastItem = d.splice(_applicableAgeGroups.length - 1, 1)
    return `${d.join(', ')} and ${lastItem}`;
}

export function getApplicableAgeGroupsDescriptionForPlayer(applicableAgeGroups) {
    return getApplicableAgeGroupsDescription(applicableAgeGroups, ageGroupMappingForPlayer)
}
export function getApplicableAgeGroupsDescriptionForAdmin(applicableAgeGroups) {
    return getApplicableAgeGroupsDescription(applicableAgeGroups, ageGroupMapping)
}

export function canJoinQuestBasedOnAgeGroup(applicableAgeGroups, ageGroup){
    if(!ageGroup){
        return false;
    }
    if(!applicableAgeGroups){
        return true
    }
    const _applicableAgeGroups = applicableAgeGroups.split(',');
    return _applicableAgeGroups.length === 0 || _applicableAgeGroups.includes('0') || _applicableAgeGroups.includes(ageGroup.toString());
}