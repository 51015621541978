import { useState, useEffect } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from 'react-bootstrap/Button';
import LoadingSpinner from '../Components/LoadingSpinner';
import { Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const PlayerStats = ()=>{

    const [stats, setStats] = useState({});
    const [loadPage, setLoadPage] = useState(false);
    const [year, setYear] = useState(2023);
    const [yearNumber, setYearNumber] = useState(-1);
    const [yearDetails, setYearDetails] = useState({});
    const [questDetails, setQuestDetails] = useState({});
    const [selectedQuestId, setSelectedQuestId] = useState();
    const [questNumber, setQuestNumber] = useState(-1);
    const [miniQDetails, setMiniQDetails] = useState({});
    const [showDropdownList, setShowDropdownList] = useState(false);

    useEffect(() => { 
        getPlayerStats();
        getActiveAndCompQuests();       
    },[]);

    const getActiveAndCompQuests = async()=>{   
        const response = await fetch('/gameapi/allActiveCompletedQuest/', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }        
      });
      const data = await response.json();
      if(! ('message' in data))  {    
        setQuestDetails(data);
        if (data.length > 0)
            setSelectedQuestId(data[0].id);

        setShowDropdownList(true);
      }  
    }

    function changeDetails(e){    
        setSelectedQuestId(e.target.value);  
       
    }

    const getPlayerStats = async () =>{
        const response = await fetch('/gameapi/playerStats/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        if(!('message' in data)){
            setStats(data);
            setLoadPage(true);
        }        
    }

    const changeYear = (e)=>{
        setYear(e.target.value);
    }

    const getPlayerYear = async (id) =>{
        const response = await fetch('/gameapi/countPlayersYear/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              aYear: `${year}`
             })
        });
        const data = await response.json(); 
        if(! ('message' in data))  {    //24th may  update: all_pending_transactions   in views
            setYearDetails(data);
            setYearNumber(data.number_players);
        }  
      }

      const getQuestStats = async () =>{
        const response = await fetch('/gameapi/countPlayersQuest/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qid: `${selectedQuestId}`
             })
        });
        const data = await response.json(); 
        if(! ('message' in data))  {    
            setMiniQDetails(data);
            setQuestNumber(data.number_players);
        }  
      }

    return (<>
    <Row >
        <Col md={12} lg={1}>  </Col>
        <Col md={12} lg={10}>
            <p className='ttsec-blue roboto-font fs-2 text-center my-3'> 
                All Player Statistics
            </p>
            {(loadPage) ?
            <>
            <p className='fs-4 currency mx-2'> Number of Players Registered: <span className='text-danger fw-bold'>{stats.number_players} </span> </p>

                      

            <Row className='my-5'>             
                <Col >
                    <Card className='mx-2'> 
                        <Card.Header>
                        <Card.Title className='roboto-font fs-4 text-primary'>
                            Gender Details
                        </Card.Title>
                        </Card.Header>
                        <Card.Body >
                                     
                        <Card.Text className='currency fs-5'>
                        <div className='table-responsive'>
                        <Table  hover className='px-5'>
                            <tbody className='px-3 py-3'>
                            <tr>
                                <td width='70%' className='px-3'>Number Male Players:</td>
                                <td className='px-3'><span className='text-danger'> {stats.number_male} </span>({stats.male_per.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>Number Female Players:</td>
                                <td className='px-3'><span className='text-danger'> {stats.number_female} </span>({stats.female_per.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>Gender Not Listed:</td>
                                <td className='px-3'><span className='text-danger'> {stats.number_no_gender} </span>({stats.no_gender_per.toFixed(1)})%</td>
                            </tr>
                            
                            </tbody>
                            
                        </Table>

                        </div>
                        
                       
                        </Card.Text>

                    </Card.Body> </Card>
                </Col>
            </Row>

            <Row className='my-5'>             
                <Col >
                    <Card className='mx-2'> 
                        <Card.Header>
                        <Card.Title className='roboto-font fs-4 text-primary'>
                            Age Details
                        </Card.Title>
                        </Card.Header>
                        <Card.Body >
                        
                        <Card.Text className='currency fs-5'>
                        
                        <Table  hover>
                            <tbody className='px-3 py-3'>
                            <tr>
                                <td width='70%' className='px-3'>Number Players 13-19:</td>
                                <td className='px-3'><span className='text-danger'> {stats.age1} </span>({stats.age1_p.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>Number Players 20-35:</td>
                                <td className='px-3'><span className='text-danger'> {stats.age2} </span>({stats.age2_p.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>Number Players 36-45:</td>
                                <td className='px-3'><span className='text-danger'> {stats.age3} </span>({stats.age3_p.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>Number Players 46-55:</td>
                                <td className='px-3'><span className='text-danger'> {stats.age4} </span>({stats.age4_p.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>Number Players 56+:</td>
                                <td className='px-3'><span className='text-danger'> {stats.age5} </span>({stats.age5_p.toFixed(1)})%</td>
                            </tr>
                            </tbody>
                            
                        </Table>
                       
                        </Card.Text>

                    </Card.Body> </Card>
                </Col>
            </Row>

            <Row className='my-5'>             
                <Col >
                    <Card className='mx-2'> 
                        <Card.Header>
                        <Card.Title className='roboto-font fs-4 text-primary'>
                            Location Details
                        </Card.Title>
                        </Card.Header>
                        <Card.Body >
                        
                        <Card.Text className='currency fs-5'>
                        
                        <Table  hover>
                            <tbody className='px-3 py-3'>
                            <tr >
                                <td width='70%' className='px-3'>North East, Trinidad:</td>
                                <td className='px-3'><span className='text-danger'> {stats.north_e} </span>({stats.north_ep.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>North West, Trinidad:</td>
                                <td className='px-3'><span className='text-danger'> {stats.north_w} </span>({stats.north_wp.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>East, Trinidad:</td>
                                <td className='px-3'><span className='text-danger'> {stats.east} </span>({stats.east_p.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>Central, Trinidad:</td>
                                <td className='px-3'><span className='text-danger'> {stats.cent} </span>({stats.cent_p.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>South East, Trinidad:</td>
                                <td className='px-3'><span className='text-danger'> {stats.south_e} </span>({stats.south_ep.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>South West, Trinidad:</td>
                                <td className='px-3'><span className='text-danger'> {stats.south_w} </span>({stats.south_wp.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>Tobago:</td>
                                <td className='px-3'><span className='text-danger'> {stats.tobg} </span>({stats.tobg_p.toFixed(1)})%</td>
                            </tr>
                            <tr>
                                <td className='px-3'>Other Locations:</td>
                                <td className='px-3'><span className='text-danger'> {stats.other} </span>({stats.other_p.toFixed(1)})%</td>
                            </tr>
                            </tbody>
                            
                        </Table>
                       
                        </Card.Text>

                    </Card.Body> </Card>
                </Col>
            </Row>

            <Row className='my-5'>             
                <Col >
            <Card className='mx-2'> 
                <Card.Header>
                <Card.Title className='roboto-font fs-4 '>
                    Number Players Joined (By Year)
                </Card.Title>
                </Card.Header>
                <Card.Body >
                                
                <Card.Text className='currency fs-5'>
                
                <Table >
                    <tbody className='px-3 py-3'>
                    
                    <tr>
                        <td width="20%">Enter Year</td>
                        <td className='px-3' width="50%">
                        <input type="number" name="currentYear" value={year} min="2023"  onChange={changeYear} required/>
                        </td>
                        <td>                         
  
                        <Button variant='warning' onClick={getPlayerYear}>
                            Get Details
                        </Button>
                        </td>
                    </tr>                 
                    
                    
                    
                    </tbody>
                    
                </Table>

                

                <div > <span className='text-primary fw-bold'>Number players joined: </span>
                    {(yearNumber < 0)?
                    <span>  </span>
                    :
                    <>
                    <span className='text-danger fw-bold'> {yearNumber}</span>
                    <hr/>
                    <p className='mt-2'> 
                        [Number Male Players: <span className='text-danger fw-bold'> {yearDetails.number_male} </span>]
                        [Number Female Players: <span className='text-danger fw-bold'> {yearDetails.number_female} </span>]
                        [Gender not listed: <span className='text-danger fw-bold'> {yearDetails.number_no_gender} </span>]
                    </p>

                     <hr/>

                     <p className='mt-2 '> 
                        [13 - 19: <span className='text-danger fw-bold'> {yearDetails.age1} </span>]
                        [20 - 35: <span className='text-danger fw-bold'> {yearDetails.age2} </span>]
                        [36 - 45: <span className='text-danger fw-bold'> {yearDetails.age3} </span>]
                        [46 - 55: <span className='text-danger fw-bold'> {yearDetails.age4} </span>]
                        [56+ : <span className='text-danger fw-bold'> {yearDetails.age5} </span>]                        
                     </p>

                     <hr/>

                     <p className='mt-2'> 
                        [North East, Trinidad: <span className='text-danger fw-bold'> {yearDetails.north_e} </span>]
                        [North West, Trinidad: <span className='text-danger fw-bold'> {yearDetails.north_w} </span>]
                        [East, Trinidad: <span className='text-danger fw-bold'> {yearDetails.east} </span>]
                        [Central, Trinidad: <span className='text-danger fw-bold'> {yearDetails.cent} </span>]
                        [South East, Trinidad: <span className='text-danger fw-bold'> {yearDetails.south_e} </span>]
                        [South West, Trinidad: <span className='text-danger fw-bold'> {yearDetails.south_w} </span>]
                        [Tobago: <span className='text-danger fw-bold'> {yearDetails.tobg} </span>]
                        [Other Locations: <span className='text-danger fw-bold'> {yearDetails.other} </span>]
                        
                     </p>
                    </>
                    
                    }
                </div>

                </Card.Text>

            </Card.Body> </Card>
                </Col>
            </Row>
        
        {(showDropdownList) ?
        <>
        <Row className='my-5'>             
                <Col >
            <Card className='mx-2'> 
                <Card.Header>
                <Card.Title className='roboto-font fs-4 '>
                    Number Players (By Quest)
                </Card.Title>
                </Card.Header>
                <Card.Body >
                                
                <Card.Text className='currency fs-5'>
                
                {(questDetails.length <= 0) ?
                <p> No Mini-Quest were created as yet.</p>
                :
                <>
                <Table >
                    <tbody className='px-3 py-3'>
                    
                    <tr>
                        <td width="20%">Select Quest</td>
                        <td className='px-3'width="50%">
                        <Form  > 
                        <Form.Group >                   
                    
                    <Form.Select className="mb-3"  value={selectedQuestId}  name = 'questId'  onChange={changeDetails} required>
                    { questDetails.map((q)=>(
                    <option key={q.id} value={q.id}> 
                        {q.name}
                    </option>
                    ))}
                                                
                    </Form.Select>
                 
            </Form.Group>
                        </Form>
                        
                        </td>
                        <td>                         
  
                        <Button variant='warning' onClick={getQuestStats}>
                            Get Details
                        </Button>
                        </td>
                    </tr>                   
                    
                    
                    
                    </tbody>
                    
                </Table>
                </>
                }
                
                

                

                <div > <span className='text-primary fw-bold'>Number players joined: </span>
                    {(questNumber < 0)?
                    <span>  </span>
                    :
                    <>
                    <span className='text-danger fw-bold'> {questNumber}</span>
                    <hr/>
                    <p className='mt-2'> 
                        [Number Male Players: <span className='text-danger fw-bold'> {miniQDetails.number_male} </span>]
                        [Number Female Players: <span className='text-danger fw-bold'> {miniQDetails.number_female} </span>]
                        [Gender not listed: <span className='text-danger fw-bold'> {miniQDetails.number_no_gender} </span>]
                    </p>

                     <hr/>

                     <p className='mt-2 '> 
                        [13 - 19: <span className='text-danger fw-bold'> {miniQDetails.age1} </span>]
                        [20 - 35: <span className='text-danger fw-bold'> {miniQDetails.age2} </span>]
                        [36 - 45: <span className='text-danger fw-bold'> {miniQDetails.age3} </span>]
                        [46 - 55: <span className='text-danger fw-bold'> {miniQDetails.age4} </span>]
                        [56+ : <span className='text-danger fw-bold'> {miniQDetails.age5} </span>]                        
                     </p>

                     <hr/>

                     <p className='mt-2'> 
                        [North East, Trinidad: <span className='text-danger fw-bold'> {miniQDetails.north_e} </span>]
                        [North West, Trinidad: <span className='text-danger fw-bold'> {miniQDetails.north_w} </span>]
                        [East, Trinidad: <span className='text-danger fw-bold'> {miniQDetails.east} </span>]
                        [Central, Trinidad: <span className='text-danger fw-bold'> {miniQDetails.cent} </span>]
                        [South East, Trinidad: <span className='text-danger fw-bold'> {miniQDetails.south_e} </span>]
                        [South West, Trinidad: <span className='text-danger fw-bold'> {miniQDetails.south_w} </span>]
                        [Tobago: <span className='text-danger fw-bold'> {miniQDetails.tobg} </span>]
                        [Other Locations: <span className='text-danger fw-bold'> {miniQDetails.other} </span>]
                        
                     </p>
                    </>
                    
                    }
                </div>

                </Card.Text>

            </Card.Body> </Card>
                </Col>
            </Row>
        </>
        :
        <></>
        
        }
            

            </>
            :
            <div className='text-center'>
                <LoadingSpinner />
            </div>
            }
            
        </Col>
        <Col md={12} lg={1}> </Col>
    </Row>

    
    
    </>);
}

export default PlayerStats;